import React from "react";
import { Text } from "@breakingwave/react-ui-components";
import GroupStatusIcon from "../../../../components/group-status-icon";
import { IGenericRequestDisplayItemsResponse, InferredGenericRequestStatusEnum } from "../../../../types/genericRequestDisplayItems";
import GenericRequestAccordion from "../../../../components/accordion/GenericRequestAccordion";
import { genericRequestStatusColours } from "../index.config";
import { useRequestPageContext } from "../_state/context";
import { GenericRequestTargetsEnum } from "../../../../types/genericRequests";
import useGroupStatusesCompletePercent from "../../../../hooks/useGroupStatusesCompletePercent";
import { UserTypeEnum } from "../../../../types/shared";
import * as S from "./index.styles";

interface IGenericRequestGroupStatusesProps {
    genericRequest: IGenericRequestDisplayItemsResponse;
    target: GenericRequestTargetsEnum;
}

const GenericRequestGroupStatuses: React.FC<IGenericRequestGroupStatusesProps> = ({
    genericRequest,
    target
}) => {

    const {
        state: {
            savedGenericRequest
        }
    } = useRequestPageContext();

    const { groupStatusPercentage } = useGroupStatusesCompletePercent(genericRequest?.GroupStatuses ?? []);

    return <>
        <GenericRequestAccordion 
            dateCreatedUtc={genericRequest.DateCreatedUtc}
            displayName={genericRequest.DisplayName}
            requestId={genericRequest.RequestId}
            applicant={genericRequest.Applicant}
            profileProgressCircleProps={{
                size: 30,
                percentage: groupStatusPercentage,
                userType: UserTypeEnum.User,
                percentagePathColour: genericRequestStatusColours.memberIcon[genericRequest.InferredGenericRequestStatus]
            }}
            defaultOpenState={(target === GenericRequestTargetsEnum.Applicant && savedGenericRequest?.MondayBoardId === genericRequest.MondayBoardId.toString())} 
            isToggleable={true}
            hasToggleIcon={true}
            mondayboardId={genericRequest.MondayBoardId}
            enableLinkToMondayboard={target === GenericRequestTargetsEnum.Assignee}>

            <S.GroupStatusesAccordionBody>
                <Text size={14} weight={500} style={{ marginBottom: 18 }}>
                    {genericRequest.InferredGenericRequestStatus === InferredGenericRequestStatusEnum.Indeterminate ? "Unable to determine the managed process status" : "Current status"}
                </Text>
                <S.AllGroupStatusesWrapper>
                    {genericRequest.GroupStatuses?.map((group, i) => (
                        <S.EachGroupWrapper key={i}>
                            <S.GroupIcon>
                                <GroupStatusIcon
                                    size={26}
                                    status={group.Status}
                                    color={
                                        genericRequestStatusColours.status[
                                            group.Status
                                        ]
                                    }
                                />
                            </S.GroupIcon>
                            <S.GroupName>{group.GroupId}</S.GroupName>
                            <S.GroupStatus
                                color={
                                    genericRequestStatusColours.status[
                                        group.Status
                                    ]
                                }
                            >
                                {group.Status}
                            </S.GroupStatus>
                        </S.EachGroupWrapper>
                    ))}
                </S.AllGroupStatusesWrapper>
            </S.GroupStatusesAccordionBody>
        </GenericRequestAccordion>
    </>;
};

export default GenericRequestGroupStatuses;
