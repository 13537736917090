import { CreateGenericRequestDomainValidationFailureReasonEnum } from "../types/genericRequests";

const genericRequestDomainValidationFailureReason = (bitwise: number): string => {
    if ((bitwise & CreateGenericRequestDomainValidationFailureReasonEnum.ProjectIsNotHomeProject) === 
            CreateGenericRequestDomainValidationFailureReasonEnum.ProjectIsNotHomeProject) {
        return "Project not found";
    }

    if ((bitwise & CreateGenericRequestDomainValidationFailureReasonEnum.RequestTypeNotConfigured) === 
            CreateGenericRequestDomainValidationFailureReasonEnum.RequestTypeNotConfigured) {
        return "Request type is not configured";
    }

    if ((bitwise & CreateGenericRequestDomainValidationFailureReasonEnum.InvalidSubscriptionType) === 
            CreateGenericRequestDomainValidationFailureReasonEnum.InvalidSubscriptionType) {
        return "Invalid subscription type";
    }    

    if ((bitwise & CreateGenericRequestDomainValidationFailureReasonEnum.ApplicantCannotBeAssignee) === 
            CreateGenericRequestDomainValidationFailureReasonEnum.ApplicantCannotBeAssignee) {
        return "Unable to create request if you are the assignee";
    }        

    return "Unknown error occurred";
};

export {
    genericRequestDomainValidationFailureReason
};
